import _ from 'lodash';

import {
    UPDATE_PROFILE,
    GET_FACULTY_BUSSINESS_TYPE,
    GET_COMPANY_TYPE,
    GET_PROFILE,
    GET_FACULTY_DEGREES,
    GET_PROVINCES,
    GET_APPLICATIONS,
    GET_SUBSCRIPTIONS,
    GET_CATEGORY,
    CHANGE_PASSWORD,
    PP_IMAGE_UPLOADED,
    RESUME_UPLOADED
} from '../actions/types';

const initialState = {
    subcriptions:null,
    applications:null,
    profile: null,
    category:null,
    faculties:null,
    degrees:null,
    provinces:null,
    company_types:null,
    pp:'',
    resume:''
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_APPLICATIONS:
            return {
                ...state,
                applications: action.payload,
            };
        case GET_SUBSCRIPTIONS:
            return {
                ...state,
                subcriptions: action.payload,
            };
        case GET_PROFILE:
            return {
                ...state,
                profile: action.payload,
            };
        case GET_CATEGORY:
            return {
                ...state,
                category: action.payload,
            };
        case GET_FACULTY_BUSSINESS_TYPE:
            return {
                ...state,
                faculties: action.payload,
            };
        case GET_COMPANY_TYPE:
            return {
                ...state,
                company_types: action.payload,
            };
        case GET_FACULTY_DEGREES:
            return {
                ...state,
                degrees: action.payload,
            };
        case GET_PROVINCES:
            return {
                ...state,
                provinces: action.payload,
            };
        case UPDATE_PROFILE:
            return {
                ...state,
                profile: action.payload,
            };
        case CHANGE_PASSWORD:
            return {
                ...state,
                changed:true,
            };
        case PP_IMAGE_UPLOADED:
            return {
                ...state,
                pp:action.payload,
            };
        case RESUME_UPLOADED:
            return {
                ...state,
                resume:action.payload,
            };
        default:
            return state;
    }
}
