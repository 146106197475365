
import React from "react"
import { NotificationManager } from "react-notifications"
import { detect } from "detect-browser"

const _ = require('lodash');
const lang = require('../langs')
const { rest } = require('./rest')
const config = require('./config')
const currencyjs = require('currency.js')
const CryptoJS  = require('crypto-js')
const moment = require('moment')

export const authLogged = () => {
    if( cacheStore().getItem(config.tokenKey) && cacheStore().getItem(config.userObjKey) ) {
         // Decode token and get user info and exp
        const userEnc = cacheStore().getItem(config.userObjKey);

        const userObj = decryptObject(userEnc)

        return userObj;
    }

    return false;
}
export const checkEmptyBiodata = (profile) => {
    const data = { 
        title:profile.title,
        about:profile.about,
        jobseekers_gender:profile.jobseekers_gender, 
        jobseekers_marital:profile.jobseekers_marital, 
        jobseekers_phone:profile.jobseekers_phone, 
        jobseekers_address:profile.jobseekers_address, 
        jobseekers_image:profile.jobseekers_image, 
        jobseekers_resume:profile.jobseekers_resume, 
        jobseekers_date:profile.jobseekers_date, 
        jobseekers_provinsi:profile.jobseekers_provinsi, 
        jobseekers_city:profile.jobseekers_city
    }

    const checkEmpty = _.reject(data, (d)=>{
        return (d !== null && d !== '' && d !== undefined && d.length !== 0)
    })

    return checkEmpty.length > 0 ? false : true
}

export const checkEmptyBiodataFields = (profile) => {
    const data = { 
        title:profile.title,
        about:profile.about,
        jobseekers_gender:profile.jobseekers_gender, 
        jobseekers_marital:profile.jobseekers_marital, 
        jobseekers_phone:profile.jobseekers_phone, 
        jobseekers_address:profile.jobseekers_address, 
        jobseekers_date:profile.jobseekers_date, 
        jobseekers_provinsi:profile.jobseekers_provinsi, 
        jobseekers_city:profile.jobseekers_city
    }

    const checkEmpty = _.reject(data, (d)=>{
        return (d !== null && d !== '' && d !== undefined && d.length !== 0)
    })

    return checkEmpty.length > 0 ? false : true
}


export const calculateProgressResume = (profile) => {

    const data = { 
        title:profile.title,
        about:profile.about,
        email:profile.email,
        jobseekers_firstname:profile.jobseekers_firstname, 
        jobseekers_gender:profile.jobseekers_gender, 
        jobseekers_marital:profile.jobseekers_marital, 
        jobseekers_phone:profile.jobseekers_phone, 
        jobseekers_address:profile.jobseekers_address, 
        jobseekers_image:profile.jobseekers_image, 
        jobseekers_resume:profile.jobseekers_resume, 
        jobseekers_date:profile.jobseekers_date, 
        jobseekers_provinsi:profile.jobseekers_provinsi, 
        jobseekers_city:profile.jobseekers_city, 
        experinceList:profile.experinceList, 
        educations:profile.educations, 
        computer:profile.computer, 
        languanges:profile.languanges
    }

    const checkEmpty = _.reject(data, (d)=>{
        return (d !== null && d !== '' && d !== undefined && d.length !== 0)
    })

    const progress = (checkEmpty.length / 17) * 100

    return 100 - Math.ceil(progress)
}


export const checkUserCV = (profile) => {

    const data = { 
        title:profile.title,
        about:profile.about,
        email:profile.email,
        jobseekers_firstname:profile.jobseekers_firstname, 
        jobseekers_gender:profile.jobseekers_gender, 
        jobseekers_marital:profile.jobseekers_marital, 
        jobseekers_phone:profile.jobseekers_phone, 
        jobseekers_address:profile.jobseekers_address, 
        jobseekers_image:profile.jobseekers_image, 
        jobseekers_resume:profile.jobseekers_resume, 
        jobseekers_date_of_birth:profile.jobseekers_date, 
        jobseekers_province:profile.jobseekers_provinsi, 
        jobseekers_city:profile.jobseekers_city, 
        work_experience:profile.experinceList, 
        educations:profile.educations, 
        skill:profile.computer, 
        language_skill:profile.languanges
    }

    const checkEmpty = _.reject(makeArrayKeyLabel(data), (d)=>{
        return (d.label !== null && d.label !== '' && d.label !== undefined && d.label.length !== 0)
    })

    return checkEmpty
}

export const setAuthToken = token => {
    if (token) {
        // Apply to every request
        rest().defaults.headers.common['Authorization'] = 'bearer ' + token;
    } else {
        // Delete auth header
        delete rest().defaults.headers.common['Authorization'];
    }
}

export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

export const makeArrayKeyLabel = (obj) => {
    let arr = []
    
    _.forIn(obj, (l, k)=> {
        arr.push({
            key:k,
            label:l
        })
    })

    return arr
}

export const encrypt = (text) => {
    if (_.isObject(text)) {
        return CryptoJS.AES.encrypt(JSON.stringify(text), config.cipher_crypt)
    } else {
        return CryptoJS.AES.encrypt(text, config.cipher_crypt)
    }
}

export const decryptObject = (crypted) => {
    const bytes = CryptoJS.AES.decrypt(crypted.toString(), config.cipher_crypt)
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))

    return decryptedData
}

export const decrypt = (crypted) => {
    const bytes = CryptoJS.AES.decrypt(crypted.toString(), config.cipher_crypt)
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8)

    return decryptedData
}

export const createNotification = (type, content) => {
    switch (type) {
        case 'info':
            NotificationManager.info(content, 'Info');
            break;
        case 'success':
            NotificationManager.success(content, 'Great!');
            break;
        case 'warning':
            NotificationManager.warning(content, 'Warning message');
            break;
        case 'error':
            NotificationManager.error(content, 'Error message');
            break;
    }
}

export const displayAvatar = (url) => {
    return _.trimEnd(process.env.GATSBY_AVATAR_URL, '/') + '/' + url
}

export const displayDateTime = (date) => {
    const dt = moment(date)
    return dt.format("DD MMM YYYY, HH:mm")
}

export const displayDate = (date) => {
    const dt = moment(date)
    return dt.format("DD MMM YYYY")
}

export const displayHumanDate = (date) => {
    const lastDt = moment(date)
    const now = moment()

    return lastDt.from(now)
}

export const diffYears = (date) => {
    const lastDt = moment(date)
    const now = moment()

    return now.diff(lastDt, 'years')
}

export const years = () => {
    let dateOb = new Date()
    let year = dateOb.getFullYear()
    let years = []
    let increments = 80
    for (let index = 0; index < increments; index++) {
        years.push(year)
        year--
    }
    return years
    
}

export const stripTags = html => {
    const regex = /(<([^>]+)>)/gi;
    const nlbr = /(\r|\n)/g;
    let filtered = html.replace(regex, "");
    return filtered.replace(nlbr, "");
};
  
export const replaceHtmlEntites = text => {
    var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
    var translate = { nbsp: " ", amp: "&", quot: '"', lt: "<", gt: ">" };
    return text.replace(translate_re, function(match, entity) {
      return translate[entity];
    });
}
  
export const currency = (number) => {
    return currencyjs(number, {
        separator: ".",
        decimal: ",",
        precision: 0,
        symbol: "IDR ",
    }).format(true)
}

export const currencyShort = (number) => {
    return currencyjs(number, {
        separator: ".",
        decimal: ",",
        precision: 0,
        symbol: "IDR ",
    }).format(true)
}

export const numberCurrency = (number) => {
    return currencyjs(number, {
        separator: ".",
        decimal: ",",
        precision: 0,
        symbol: "",
    }).format(true)
}

export const currencyRaw = (text) => {
    return currencyjs(text, {
        separator: ".",
        decimal: ",",
        precision: 0,
    })
}

export const price = (item, type = 'sell_price', with_label = false) => {
    if (type == 'sell_price' && item.on_sale) {
        return (<span className="price">
            <span>{currency(item.sale_price)}</span>
            <del>{currency(item.regular_price)}</del>
        </span>
        )
    }

    if (type == 'buy_price') {
        return (<span className="capital-price">
            {with_label === true && <span className="badge badge-secondary pr-1">B</span>}
            <span className="pl-1">{currency(item.capital_cost)}</span>
        </span>
        )
    }

    return (
        <span className="price">
            {with_label === true && <span className="badge badge-danger">S</span>}
            <span className="pl-1">{currency(item.regular_price)}</span>
        </span>
    )
}

export const showErrors = data => {
    let errors = "";
  
    if (_.has(data, "messages") && typeof data.messages === "object") {
      let combines = _.mapValues(data.messages, item => {
        return _.join(item, "\n");
      });
  
      _.map(combines, item => {
        errors += item;
      });
  
      return errors;
    } else if (_.has(data, "messages") && typeof data.messages === "string") {
      return data.messages;
    }
  
    return "Something error! Not found or wrong address url";
};

export const urlExists = (url) => {
    var http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    return http.status !== 404;
}

export const validateRequired = (data, filterData) => {
    let output = {}
    filterData.map(key => {
        if (_.has(data, key) && (data[key] === '' || data[key] === null || data[key] === undefined)) {
            output[key] = lang('required.' + key)
        }
    })

    return output;
}

export const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const isSuperadmin = (payload) => {
    if (_.has(payload, 'id')) {
        return (_.toLower(payload.role) == 'administrator' || _.toLower(payload.role) == 'manager') ? true : false;
    } else if (_.has(payload, 'user')) {
        return (_.toLower(payload.user.role) == 'administrator' || _.toLower(payload.user.role) == 'manager') ? true : false;
    }

    return false;
}

export const isChasier = (payload) => {
    if (_.has(payload, 'id')) {
        return _.toLower(payload.role) === 'staff' ? true : false;
    } else if (_.has(payload, 'user')) {
        return _.toLower(payload.user.role) === 'staff' ? true : false;
    }

    return false;
}

export const inputNumberOnly = (evt) => {
    let theEvent = evt;
    let key
    // Handle paste
    if (theEvent.type === 'paste') {
        key = window.event.clipboardData.getData('text/plain');
    } else {
        // Handle key press
        key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
    }
}

export const encapsulateErrors = (data) => {
    let errors = []
    if (_.has(data, 'validated')) {
        if (_.has(data, 'messages') && typeof data.messages === 'object') {
            return _.mapValues(data.messages, (item) => {
                return _.join(item, "\n")
            })
        } else if (_.has(data, 'messages') && typeof data.messages === 'string') {
            return data.messages
        }

        return errors;
    }

    return data
}

export const findStatusText = data => status => {
    return _.findKey(data, status) ? true : false;
}

export const encodeUri = uri => {
    const str = uri.replace(" ", '%20')
    return encodeURIComponent(str)
}

export const pageTitle = title => {
    return title + " | " + config.app_title
}

export const cacheStore = (type = 'local') => {
    if (type === 'local') {
        return localStorage
    } else if (type === 'session') {
        return sessionStorage
    }
}

export const isPremium = (profile) => {
    return ( 
        profile && _.has(profile, 'subscription') && profile.subscription && 
        _.has(profile.subscription, "status") && 
        profile.subscription.status == 'ok' 
    ) ? true : false;
}

export const isPremiumPending = (profile) => {

    return ( 
        profile && _.has(profile, 'subscription') && profile.subscription && 
        _.has(profile.subscription, "status") && 
        (
            profile.subscription.status == 'pending' || 
            profile.subscription.status == 'waiting' || 
            profile.subscription.status == 'need_approval'
        ) 
    ) ? true : false;
}


export const isPremiumProcess = (profile) => {
    return ( 
        profile && 
        _.has(profile, 'subscription') && 
        _.has(profile.subscription, "status") &&
        (
            profile.subscription.status == 'ok' || 
            profile.subscription.status == 'pending' || 
            profile.subscription.status == 'waiting' || 
            profile.subscription.status == 'need_approval'
        ) 
    ) ? true : false;
}

export const isMobile = () => {
    const browser = detect()

    return browser.os == 'Android OS' || browser == 'iOS' || browser.os == 'android' || browser.os == 'BlackBerry OS' || browser.os == 'Windows Mobile'
}