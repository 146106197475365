const en = () => {
    return new Map([
        ['required_field', '<%= field %> is required'],
        ['required.email', 'Email address is required field'],
        ['required.username', 'Username is required field'],
        ['required.password', 'password is required field'],
        ['invalid.email', 'Email address is not valid format'],
        ['invalid.paid_date', 'Paid date is not valid format'],
        ['invalid.password_chars', 'Min. 8 password characters'],
        ['first_name', 'first name'],
        ['last_name', 'Last name'],
        ['email', 'Email'],
        ['mobile', 'Phone number'],
        ['logout', 'Log out'],
        ['wrong.password_matched', 'Your confirm password is not matched with password'],
        ['success.login', 'Login successfully'],
        ['error.server_unknown', 'Something went wrong in server. Please contact administrator'],
        ['remember_me', 'Remember me'],
        ['success.logout', 'You are logged out'],
        ['month_names', [
            {
                key:'01',
                label:'January'
            },
            {
                key:'02',
                label:'February'
            },
            {
                key:'03',
                label:'March'
            },
            {
                key:'04',
                label:'April'
            },
            {
                key:'05',
                label:'May'
            },
            {
                key:'06',
                label:'June'
            },
            {
                key:'07',
                label:'July'
            },
            {
                key:'08',
                label:'August'
            },
            {
                key:'09',
                label:'September'
            },
            {
                key:'10',
                label:'October'
            },
            {
                key:'11',
                label:'November'
            },
            {
                key:'12',
                label:'December'
            }
        ]]
    ])
}

export default en