import React from "react"
import config from "../utils/config"
import { cacheStore, decryptObject, encrypt } from "../utils/helpers"

const defaultState = {
  auth: false,
  user: null,
  setUpdateUser: () => {},
}
const UserContext = React.createContext(defaultState)

class UserCurrentProvider extends React.Component {
  state = {
    auth: false,
    user: null
  }

  setUpdateUser = (newObj={}) => {
    
  }

  componentDidMount() {
    if( cacheStore().getItem(config.userObjKey) ) {
        // Decode token and get user info and exp
        const userEnc = cacheStore().getItem(config.userObjKey);
        const userObj = decryptObject(userEnc)

        this.setState({
            user:userObj
        })
    }
  }

  render() {
    const { children } = this.props
    const { user, auth } = this.state
    return (
      <UserContext.Provider
        value={{
          user,
          auth,
          setUpdateUser: this.setUpdateUser,
        }}
      >
        {children}
      </UserContext.Provider>
    )
  }
}
export default UserContext
export { UserCurrentProvider }