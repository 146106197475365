import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import { navigate } from "gatsby"
import { LOADER_START, LOADER_COMPLETE, LOADER_ERROR, GET_ERRORS, CLEAR_CURRENT_PROFILE } from '../actions/types'
import { has, toLower, startsWith } from "lodash"
import config from './config'
import { cacheStore } from './helpers'

const initialState = {};

const loadingConnection = store => next => action => {

    if (!action.promise) {
        return next(action)
    }

    function makeAction(ready, data) {
        const newAction = Object.assign({}, action, { ready }, data)
        delete newAction.promise
        return newAction
    }

    store.dispatch({
        type: LOADER_START, payload: {
            status: 'start'
        }
    })

    next(makeAction(false))

    return action.promise.then(
        result => {
            store.dispatch({
                type: LOADER_COMPLETE, payload: {
                    status: 'complete'
                }
            })
        },
        error => {
            let err = error
            store.dispatch({
                type: LOADER_ERROR, payload: {
                    status: 'error',
                    data: err.response ? err.response : err,
                }
            })
            console.log('Caught Exception!', error)
        }
    )

}

const middlewareLogout = store => next => action => {
    if( action.type == GET_ERRORS ) {
        if( has(action.payload, 'data') ) {
            if( startsWith(toLower(action.payload.data), 'unauthorized') ) {
                // Remove token from sessionStorage
                cacheStore().removeItem(config.tokenKey);
                // Remove token from sessionStorage
                cacheStore().removeItem(config.userObjKey);

                store.dispatch({
                    type:CLEAR_CURRENT_PROFILE
                })

                setTimeout(function(){
                    navigate('/login');
                }, 3000)
            }   
        }
    }
    next(action)
}


const middleware = [
    thunk,
    loadingConnection,
    middlewareLogout
];


// dev tools middleware
const composeSetup = process.env.NODE_ENV !== 'production' && typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose

const store = createStore(
    rootReducer,
    initialState,
    composeSetup(
        applyMiddleware(...middleware)
    )

);

export default store;